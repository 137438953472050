.TextArea {
  display: block;
  width: 100%;
  margin-top: 0.2em;
  margin-bottom: 1em;
  background-color: #364c79;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  padding: 0.5em;
}

.TextAreaLabel {
  display: block;
  color: white;
  font-size: .75em;
  margin-bottom: 0em;
  text-align: left;
  margin-left: .5em;
}

.TextAreaText {
  display: flex;
  color: white;
  vertical-align: text-bottom;
  text-shadow:   0px 0px 10px #7ad0e1, 0px 0px 1px #7ad0e1, 0px 0px 2px #7ad0e1, 0px 0px 3px #7ad0e1;
  font-size: 1em;
  letter-spacing: .10ch;
  line-height: .5em;
  padding-top: 1em;
  padding-left: 1em;
}

textarea::placeholder {
  visibility: hidden;
}