.App-link {
  color: #61dafb;
}
html {
  background-color: #060a13;
  height: 100%;
  width: 100%;
  margin: 0%;
  padding: 0%;
}
body {
  height: 100%;
  width: inherit;
}
a { 
  text-decoration: none; 
}
.DefaultBackground {
  background-color: #060a13;
  height: 100%;
  width: inherit;
}
.SectionHeaderText {
  color: white;
  font-size: 1.5em;
  text-shadow: 0px 0px 3px #9ee84d, 2px 2px 12px #7ad0e1, -1px -1px 10px #7ad0e1;
  text-align: center;
  margin-bottom: .0em;
  margin-top: 0em;
  letter-spacing: .10ch; 
  font-weight: 630;
}
.BodyText {
  flex-direction: row;
  color: white;
  text-shadow:   0px 0px 10px #7ad0e1, 0px 0px 1px #7ad0e1, 0px 0px 2px #7ad0e1, 0px 0px 3px #7ad0e1;
  font-size: 1.4em;
  text-align: center;
  letter-spacing: .10ch;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}
.BodyTextEmphasis {
  color: white;
  text-shadow:   0px 0px 3px #9ee84d, 2px 2px 12px #7ad0e1, -1px -1px 10px #7ad0e1;
  font-size: 1em;
  text-align: center;
  letter-spacing: .10ch;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: .3em;
  margin-right: .3em;
  display: inline-block;
}
.GridLayout {
  display: flex;
  grid-template-columns: repeat(auto-fill, 33%);
  overflow: hidden;
  flex-wrap: wrap;
}
.GridItem {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  padding: 2em;
  min-width: 250px;
  max-width: 360px;
  justify-content: center;
  align-items: center;
}
.BlueImage {
  max-width: 100%;
  height: auto;
  border-width: 2px;
  border-style: solid;
  border-color: #7ad0e1;
  border-radius: 10px;
  margin-top: 10px;
}
