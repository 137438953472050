.DefaultBackground {
  background-color: #060a13;
  height: 100%;
  width: inherit;
}

.SectionHeaderText {
  color: white;
  font-size: 3em;
  text-shadow: 0px 0px 3px #9ee84d, 2px 2px 12px #7ad0e1, -1px -1px 10px #7ad0e1;
  text-align: center;
  margin-bottom: .75em;
  margin-top: 0em;
  letter-spacing: .10ch; 
}

.BodyText {
  display: flex;
  flex-direction: column;
  color: white;
  text-shadow:   0px 0px 10px #7ad0e1, 0px 0px 1px #7ad0e1, 0px 0px 2px #7ad0e1, 0px 0px 3px #7ad0e1;
  font-size: 1.4em;
  text-align: center;
  letter-spacing: .10ch;
  margin-right: 1em;
}

.SignUpContainer {
  margin: 0 auto;
  max-width: 500px;
  padding: 2em;
  background-color: #1e2947;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
}

.SignUpButton {
  display: block;
  width: 100%;
  padding: 0.5em;
  background-color: #7ad0e1;
  color: #10162b;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.SignUpButton:hover {
  background-color: #61dafb;
}

textarea {
  resize: none;
}

select {
  background-color: #10162b;
  border: 2px solid #7ad0e1;
  border-radius: 10px;
  color: white;
  font-size: 1em;
  letter-spacing: 0.10ch;
  resize: none;
  text-shadow: 0px 0px 10px #7ad0e1, 0px 0px 1px #7ad0e1, 0px 0px 2px #7ad0e1, 0px 0px 3px #7ad0e1;
}

select.SignUpInput {
  display: block;
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: .5em;
  margin-left: 0em;
  padding: .75em;
  padding-left: 1em;
  padding-right: 10em;
  background-color: #364c79;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: .75em;
}
