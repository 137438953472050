.TotalHeaderBox {
  display: absolute;
  width: inherit;
  height: 100%;
  background-color: #060a13;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: #7ad0e1;
  margin-bottom: 60px;
  align-items: center;
  justify-content:space-between;
}
.TitleHeaderBox {
  display: flex;
  margin-left: 2em;
}
.HeaderText {
  color: white;
  text-shadow: 0px 0px 3px #9ee84d, 2px 2px 12px #7ad0e1, -1px -1px 10px #7ad0e1; 
  font-size: 2em;
  width: 100%;
  letter-spacing: .10ch;
  text-align: center;
}
.LinksHeaderBox {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.LinkHeaderBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
}
/*make a drop down menu for the links*/
.LinkDropDownBox {
  display: none;
  position: absolute;
  background-color: #060a13;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.LinkVerticalBox {
  display: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.HeaderLinkText {
  color: white;
  text-shadow: 1px 1px 6px #9ee84d, -1px -1px 6px #9ee84d;
  font-size: 1.5em;
  font-weight: 600;
}
.unstyledimg {
  text-shadow: 1px 1px 6px #9ee84d, -1px -1px 6px #9ee84d;
  margin-right: 20px;
}
