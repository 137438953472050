.Select {
  display: block;
  width: 100%;
  margin-top: 0.2em;
  margin-bottom: 1em;
  background-color: #364c79;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  color: white;
  padding: 0.5em;
}

.SelectLabel {
  display: block;
  color: white;
  font-size: 0.75em;
  margin-bottom: 0em;
  text-align: left;
  margin-left: 0.5em;
}

.Select option {
  background-color: #060a13;
  color: white;
  font-size: 1em;
}

.Select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #7ad0e1;
}
